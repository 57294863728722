import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import './LineChart.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as marketActions from '../../../actions/market_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';


// Displays the splits for the different plans on the Summary page

class SplitLineChart extends Component {

    componentDidUpdate() {
        //check to see if an edit requires a new series to be added
        let chart = this.refs.chart.getChart();
        let newSeries = this.handleData(this.props.plans)

        if (newSeries.length > chart.series.length) {
            let seriesToAdd = newSeries[newSeries.length - 1]
            // for (let i = 0; i < newSeries.length - 2; i++) {
            //     chart.series[i].updateSeries(newSeries)
            // }
            chart.addSeries(seriesToAdd)
        }

    }

    handleData = (data) => {
        if (data && data[0] != undefined && data[0]['tiers'] != undefined && data[0]['tiers'][0]['split'] != undefined) {
            let splits = []
            let maxGci = 0
            for (let i = 0; i < data.length; i++) {
                let title = data[i]['title']
                let splitData = []
 
                for (let j = 0; j < data[i]['tiers'].length; j++) {
                    let minx = parseInt(data[i]['tiers'][j]['min'])
                    let y = data[i]['tiers'][j]['split'] * 100
                    // y = parseInt(y.split('%')[0])
                    splitData.push([minx, y])
                    if (minx > maxGci) maxGci = minx

                    if (data[i]['tiers'][j]['max']) {
                        let maxx = parseInt(data[i]['tiers'][j]['max'])
                        splitData.push([maxx, y]) 
                        if (maxx > maxGci) maxGci = maxx
                    }
                }
                
                splits.push({
                    name: title,
                    data: splitData
                })
                // console.log(splits)
     
            }
            // console.log(maxGci)
            //extend lines out to the end
            splits.forEach( split => {
                if (split.data[split.data.length - 1][0] < maxGci) {
                    // console.log(split.data[split.data.length -1])
                    let x = maxGci
                    let y = split.data[split.data.length -1][1]
                    split.data.push([x, y])
                }
            })
            return splits
        } else {
            return [{
                        showInLegend: false,
                        name: "some name 2",
                        data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1]
                    },
                    {
                        showInLegend: false,
                        name: "some name",
                        data: [23.9, 73.5, 103.4, 123.2, 143.0, 173.0, 133.6, 141.5, 214.4, 190.1]
                    }]
        }
    }




    state = {
        config : {
            colors: ['#9FAD78', '#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6'],
            chart: {
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            series: this.handleData(this.props.plans),
            title: {
                text: 'Plan Summary',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 0,
                valueSuffix: '%'
            },
            xAxis: {
                max: 216000,
                min: 0
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: "Split (%)"
                }
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: "GCI ($)"
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            }
        }
    };
    
 
    render() {
    //   const chart  = React.createElement(ReactHighcharts, { config: this.state.config });

      return (
        <div className="line-chart" style={ {width: this.props.width} }>
             <ReactHighcharts config={this.state.config} ref="chart"></ReactHighcharts>
        </div>
      );
    }
  }
  

  const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        currentPlan: state.current,
        market: state.market
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SplitLineChart)