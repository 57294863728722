import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';
import * as marketActions from '../../../actions/market_actions';
import './MarketHeadline.css'

// Displays the Headline data for the market Selected
// market overview, average sales, and sum of sales


class MarketHeadline extends Component {
    render() {
        
      return (
        <div className="market-headline">
              <section>
                  <div className="market-summary">
                      <h1>${Math.round((this.props.market.marketoverview.SUM_SalePrice__c / 1000000)).toLocaleString()}M</h1>
                      <p>Total Market Sales (LTM)</p>
                  </div>
                  <div className="market-summary">
                      <h1>${Math.round(this.props.market.marketoverview.AVG_SalePrice__c).toLocaleString()}</h1>
                      <p>Average Sale Price (LTM)</p>
                  </div>
                  <div className="market-summary">
                      <h1>{(this.props.market.marketoverview.Count__c).toLocaleString()}</h1>
                      <p>Market Transactions (LTM)</p>
                  </div>
              </section>
            </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
      return {
          plans: state.plans,
          current: state.current,
          market: state.market
      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          planAction: bindActionCreators(PlanActions, dispatch),
          currentAction: bindActionCreators(currentPlanActions, dispatch),
          marketAction: bindActionCreators(marketActions, dispatch)
      }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MarketHeadline)