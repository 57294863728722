import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';


class EffectiveSplitChart extends Component {

    componentDidUpdate() {
        let chart = this.refs.chart.getChart();
        if (this.props.effectiveSplitPlot != undefined) {
            this.handleData(this.props.effectiveSplitPlot)
        }
    //    console.log(this.props.plotbandData)
       if (this.props.plotbandData != undefined) {
           this.constructPlotbands(this.props.plotbandData)
       }

       if (this.props.maxChartVal > 0) {
            chart.xAxis[0].update({
                max: this.props.maxChartVal
            }); 
         }
    }

    constructPlotbands = (data) => {
        if (data['Econ Impact'] != undefined) {
        let indices = []
        data['Econ Impact'].forEach( (label, idx) => {
            if (label === '2019 Total GCI') {
                indices.push(idx)
            }
        })

        let gciAmounts = indices.map( index => {
            return data['Accelerator Plan'][index]
        })

        let low = gciAmounts[this.props.currentSeg] * .8
        let high = gciAmounts[this.props.currentSeg] * 1.2
        let plotColors = [
            'rgba(129, 212, 119, .5)',
            'rgba(119, 192, 212, .5)',
            'rgba(224, 62, 62, .5)',
            'rgba(159, 159, 159, .5)'
        ]

        let chart = this.refs.chart.getChart();
        chart.xAxis[0].addPlotBand({
            from: low,
            to: high,
            color: plotColors[this.props.currentSeg],
            id: 'plot-band-1'
        })
        chart.redraw()
      }
    }

    handleData = (data) => {
        if (data && data[0] != undefined) {   
       
            let segView = this.props.currentSeg
            let chartSeries = Object.keys(data[segView]).map( plan => {

                let negativesRemoved = data[segView][plan].map( point => {
                    if (point[1] < 0) {
                        point[1] = 0
                        return point
                    } else {
                        return point
                    }
                })
                return {
                    name: plan,
                    data: negativesRemoved
                }
            })

            let chart = this.refs.chart.getChart();

            chartSeries.forEach( (series, idx) => {
                chart.addSeries(series, true)
            })
            chart.redraw()
            return chartSeries

        } else {
            console.log('fire again??')
            return []
        }
    }


    state = {
        config : {
            colors: ['#9FAD78', '#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6', '#C1CAA9', '#ACECA1', '#DDD5DE', '#E6EBE0'],
            chart: {
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            series: this.handleData(this.props.effectiveSplitPlot),
            title: {
                text: 'Effective Agent Split by Plan',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            credits: {
                enabled: false
            },
            xAxis: {
                max: 216000
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: "Effective Split (%)",
                },
                labels: {
                    formatter: function() {
                        return Math.round(this.value * 100)
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return "Effective split: <b>" + (this.y * 100).toFixed(2) + "%</b><br>" + "Agent take-home: <b>$" + (Math.round(this.y * this.x)).toLocaleString() + "</b>"
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            }
        }
    };
    
    render() {

      return (
        <div className="line-chart" style={ {width: this.props.width} }>
             <ReactHighcharts config={this.state.config} ref="chart"></ReactHighcharts>
        </div>
      );
    }
  }
  
  export default EffectiveSplitChart