import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../actions/plan_actions';
import * as marketActions from '../../actions/market_actions';
import * as currentPlanActions from '../../actions/current_plan_actions';
import * as userActions from '../../actions/user_actions';
import Auth  from '../Auth/Auth';
import './MarketSelect.css';
import '../../App.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log(BASE_URL);

// Simple Select for selecting markets currently available in optimus

const market_map = {
  'Arizona': 'Arizona',
  'Atlanta': 'Atlanta',
  'Austin': 'Austin',
  'Carolinas': 'Carolinas',
  'Chicago': 'Chicago',
  'Cincinnati': 'Cincinnati',
  'Columbus': 'Columbus',
  'Connecticut': 'Connecticut',
  'Dallas_FW': 'Dallas/Fort Worth',
  'Denver': 'Denver',
  'Florida_NW': 'North Western Florida',
  'Hawaii': 'Hawaii',
  'Houston': 'Houston',
  'Indiana': 'Indiana',
  'Midatlantic': 'Mid-Atlantic',
  'Minnesota_Metro': 'Minnesota Metro',
  'New_England': 'New England',
  'New_Jersey': 'New Jersey',
  'Philadelphia': 'Philadelphia',
  'Pittsburgh': 'Pittsburgh',
  'Sacramento': 'Sacramento',
  'Salt_Lake_City': 'Salt Lake City',
  'Socal': 'Socal',
  'St_Louis': 'St. Louis',
  'Westchester': 'Westchester',
  'Maine':'Maine',
  'New_Hampshire':'New Hampshire',
  'Boston':'Boston',
  'Rhode_Island':'Rhode Island',
  'Western_Massachusetts':'Western Massachusetts',
  'Phoenix':'Phoenix',
  'Tuscon':'Tuscon',
  'Sedona':'Sedona',
  'Charlotte_NC':'Charlotte NC',
  'Charleston_SC':'Charleston SC',
  'Columbia_SC':'Columbia SC',
  'Orange_County': 'Orange County',
  'Los_Angeles': 'Los Angeles',
  'San_Diego':'San Diego',
  'San_Francisco':'San Francisco',
  'Baltimore':'Baltimore',
  'Washington_DC':'Washington DC',
  'Long_Island':'Long Island',
  'Central_Pennsylvania':'Central Pennsylvania'
}

class MarketSelect extends Component {

  state = {
      username: "",
      password: "",
      hasError: false,
      errorMsg: "Something went wrong."
  }

  handleInputChange = (event) => {
      const target = event.target;
      const name = target.name;
      this.setState({
        [name]: target.value
      });
  }

  initialMarketSelect = (e) => {
    //get the market name and market code (from market_map) and send action to set the current market
      for (let market in market_map) {
        if (e.target.value === market_map[market]) {
          this.props.marketAction.selectMarket(e.target.value, market)
        }
      }
  }

  handleMarketSelect = (e) => {
    //get the market name and market code (from market_map) and send action to set the current market
    if (e) {
      for (let market in market_map) {
        if (e.target.value === market_map[market]) {
          this.props.marketAction.selectMarket(e.target.value, market)
        }
      }
    }

    //auth
    let username = this.state.username
    let password = this.state.password
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));
    this.props.userActions.setUser(username)

    //load appropriate market data
    let url = BASE_URL + "/get-overview-data?market=" + this.props.market.code
    fetch(url, {
      method: 'GET',
      headers: headers
    })
    .then( res => {
      if (res.ok) { 
        return res.json()
      } else {
        console.log(res)
        if (res.status == 401) {
          throw new Error('You have entered incorrect credentials. Please try again.')
        } else if (res.status == 500) {
          throw new Error('Username not found.')
        } else {
          throw new Error('Something went wrong.')
        }
      }
    })
    .then ( data => {
      console.log('token is here:')
      console.log(data)
      this.props.userActions.setToken(data.token)
      let currentYear = data.result.filter((row) => row.year == 0 )[0]
      let sortedYears = data.result.sort((a,b) => {
          if (a.year < b.year) return -1;
          if (a.year > b.year) return 1;
          return 0;
      })
      let segmentdata = data.segments
      this.props.marketAction.setMarketOverview(currentYear)
      this.props.marketAction.setFullMarketData(sortedYears)
      this.props.marketAction.setSegments(segmentdata)
      this.props.marketAction.setAgentSplit(data.agentSplit)
      //navigate to the next page
      this.props.history.push('/market-overview')
    })
    .catch( error => {
        this.setState({
          hasError: true
        })
        if (error == "TypeError: Failed to fetch") {
          this.setState({
            errorMsg: "Could not connect to database."
          })
        } else {
          this.setState({
            errorMsg: error.message
          })
        }
    })

  }

  componentDidMount() {
    //clear plans so that default plans will load properly on navigation to /plan-viewer
    this.props.planAction.clearPlans()
  }

  render() {
    let market_options = []
    for (let market in market_map) {
      market_options.push(
        <option key={market}>{market_map[market]}</option>
      )
    }

    return (
      <div className="market-select">
        <div className="optimus-title-container">
          <h2 className="optimus-title">
            <div className="rectangle-top"></div>
            <div className="rectangle-bottom"></div>
              Optimus
          </h2>
        </div>

        <h1 className="market-select-title">Select a Market</h1>
        <p className="market-select-message">View insights to gain an understanding and context for creating effective commissions plans.</p>
        <select value={this.props.market.name} onChange={this.initialMarketSelect} className="select-rectangle" >
          <option disabled hidden value=''></option>
          {market_options}
        </select>
        <Auth handleInputChange={this.handleInputChange} />
        {this.state.hasError ? <p>{this.state.errorMsg}</p> : null}
        {this.props.market.name != '' ? <button onClick={ () => {
          this.handleMarketSelect()
        }} className='btn waves-effect nav-continue' id="continue-btn">Continue</button> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        current: state.current,
        market: state.market,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketSelect)
