import React, { Component } from 'react';
import Nouislider from 'react-nouislider';
import './SimpleSelect.css';
import * as wNumb from 'wnumb';


class SimpleSelect extends Component {
    render() {
      let formatter = [];
      for(let i = 0; i < this.props.value.length; i++) {
        formatter.push(wNumb({
          decimals: this.props.decimals ? this.props.decimals : 0,
          suffix: this.props.suffix ? this.props.suffix : '',
          prefix: this.props.prefix ? this.props.prefix : '',
          thousand: ','
        }));
      }

      return (
        <div className="simple-select simple-spacing" style={this.props.style}>
        <span className="switch-ctn">
        <label className="switch-label">{this.props.label}</label>
        </span>
        <div className="slider-container-edit">
            
                          <Nouislider
              range={{min: 0, max: this.props.max}}
              onSlide={evt=> this.props.setVal(evt)}
              start={this.props.value}
              tooltips={formatter}
              step={this.props.step ? this.props.step : 10}
              />
            </div>
        </div>
      );
    }
  }
  
  export {SimpleSelect}