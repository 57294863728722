import React, { Component } from 'react';


class Auth extends Component {

    render() {
        if (window.location.pathname !== '/') return null;

        return ( 
            <div style={{paddingTop: '25px'}}>
                <form>
                    <label>
                        Username:
                        <input style={{paddingLeft: '5px'}} type="text" name="username" onChange={this.props.handleInputChange} />
                    </label>
                    <label>
                        Password:
                        <input style={{paddingLeft: '5px'}} type="text" name="password" type="password" onChange={this.props.handleInputChange}/>
                    </label>
                </form>
            </div>
        )
    }
  }


  export default Auth