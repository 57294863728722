import React, { Component } from 'react';
import './MarketCompetitors.css'

class MarketCompetitors extends Component {

    generateCompetitors = (data) => {
        if (!data['Top_1_unique_agents']) {
            return []
        }
        let competitors = []

        for (let i = 1; i < 11; i++) {
            let rank = i
            let broker = `Top_${i}_brand`
            let agents = `Top_${i}_unique_agents`
            let agentsltm = `Top_${i}_PERC_1Y_unique_agents`
            let offices = `Top_${i}_unique_offices`
            let officesltm = `Top_${i}_PERC_1Y_unique_offices`
            let sides = `Top_${i}_total_sides`
            let sidesltm = `Top_${i}_PERC_1Y_total_sides`
            let asp = `Top_${i}_average_saleprice`
            let aspltm = `Top_${i}_PERC_1Y_average_saleprice`

            competitors.push({
                rank: rank,
                broker: data[broker],
                agents: (data[agents]).toLocaleString(),
                agentsltm: (data[agentsltm] * 100).toFixed(1) + '%',
                offices: data[offices],
                officesltm: (data[officesltm] * 100).toFixed(1) + '%',
                sides: Math.round(data[sides]).toLocaleString(),
                sidesltm: (data[sidesltm] * 100).toFixed(1) + '%',
                asp: Math.round(data[asp]).toLocaleString(),
                aspltm: (data[aspltm] * 100).toFixed(1) + '%'
            })
        }

        return competitors
    }

    render() {
      let competitors = this.generateCompetitors(this.props.data)
      const competitorRows = competitors.map( (comp, index) => {
      let highlightClass = comp.broker === 'NRT' ? 'highlight' : ''
        return (
            <tr className={highlightClass} key={index}>
                <td>{comp.rank}</td>
                <td>{comp.broker}</td>
                <td>{comp.agents}</td>
                <td>{comp.agentsltm}</td>
                <td>{comp.offices}</td>
                <td>{comp.officesltm}</td>
                <td>{comp.sides}</td>
                <td>{comp.sidesltm}</td>
                <td>${comp.asp}</td>
                <td>{comp.aspltm}</td>
            </tr>
        )
      })

      return (
        <div className="competitor-table">
            <table ref="main">
                <thead>
                <tr className="table-header">
                    <th></th>
                    <th ></th>
                    <th colSpan="2">#Agents</th>
                    <th colSpan="2">#Offices</th>
                    <th colSpan="2">#Total Sides</th>
                    <th colSpan="2">ASP</th>
                </tr>
                <tr className="table-header">
                    <th>Rank</th>
                    <th>Broker</th>
                    <th>Agents</th>
                    <th>&Delta; Agents LTM</th>
                    <th>Offices</th>
                    <th>&Delta; Office LTM</th>
                    <th>Sides</th>
                    <th>&Delta; Sides LTM</th>
                    <th>ASP</th>
                    <th>&Delta; ASP LTM</th>
                </tr>
                </thead>
                <tbody>
                    {competitorRows}
                </tbody>
            </table>
        </div>
      );
    }
  }
  
  
  export default MarketCompetitors