// sends the optimizization status to the state of the application. This is required because optimzation can take up to five mintues 
// and this allows the request to take place in the background. 

export default(state = { planName : null, inProgress: false }, action) => {
    switch(action.type) {
        case 'optimizing_plan':
            state.planName = action.payload.state.planName;
            state.inProgress = action.payload.state.inProgress;
            return Object.assign({}, state);
        case 'finish_optimization':
            state.inProgress = action.payload.state.inProgress;
            return Object.assign({}, state)
        default:
            return state;
    }
}

