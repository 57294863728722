export const selectMarket = (market, code) => {
    return {
        type: "select_new_market",
        payload: {
            market: market,
            code: code
        }
    }
}

export const setMarketOverview = (data) => {
    return {
        type: "set_market_overview",
        payload: {
            marketoverview: data
        }
    }
}

export const setFullMarketData = (data) => {
    return {
        type: "set_full_market_data",
        payload: {
            fullmarketdata: data
        }
    }
}

export const setSegments = (data) => {
    return {
        type: "set_segments",
        payload: {
            segments: data
        }
    }
}

export const setAgentSplit = (data) => {
    return {
        type: "set_agent_split",
        payload: {
            agentSplit: data
        }
    }
}

export const clearMarket = (data) => {
    return {
        type: "clear_market",
        payload: {
            data: null
        }
    }
}