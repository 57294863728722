import React, { Component } from 'react';
import './ToastMessage.css';
import { PulseLoader, PacmanLoader } from 'react-spinners';


class ToastMessage extends Component {


  render() {
    return (
      <div className="toast-message">

           <div className="optimizing">Optimizing in progress</div> 
           <div className="fix-loader">
           <PacmanLoader
                sizeUnit={"px"}
                size={20}
                color={'yellow'}
                loading={this.props.loading}
                />
           </div>

      </div>
    );
  }
}

export default ToastMessage