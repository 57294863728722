export const dummyNewCard = {
    title: 'New Plan',
    tiers: [
        {
            tier: 1,
            min: '0',
            max: '50000',
            split: '63%',
            gci: 75000,
        },
        {
            tier: 2,
            min: '50001',
            max: '80000',
            split: '66%',
            gci: 91756,
        },
        {
            tier: 3,
            min: '80001',
            max: '125000',
            split: '70%',
            gci: 158333,
        },
        {
            tier: 4,
            min: '125001',
            max: '175000',
            split: '74%',
            gci: 158333,
        },
        {
            tier: 5,
            min: '175000',
            max: '250000',
            split: '78%',
            gci: 158333,
        },
        {
            tier: 6,
            min: '250001',
            max: '350000',
            split: '82%',
            gci: 158333,
        },
        {
            tier: 7,
            min: '350000',
            max: '',
            split: '86%',
            gci: 158333,
        },
        {
            tier: 8,
            min: '350000',
            max: '',
            split: '90%',
            gci: 158333,
        }
    ],
    target: 1,
    recruitingTarget: '10%',
    currentAgents: 9,
    planCap : null,
    cap: 0,
    afterCap : '0%',
    agentsAchievingCap: '4.23%',
    gciToCap: '10%',
    company : {
        cap: '35K'
    },
    gciTo90Split: '23%',
    franchiseFee: '6%',
    transactionFee: 150,
    deskFee: 300,
    annualFee: 750,
    marketingFee: 250,
    rationale: '',
    editable: true
}