import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import './LineBarCombo.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as marketActions from '../../../actions/market_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';

// Displays the Average NRT Split and take home on the Market Overview Page
// <LineBarCombo />

class LineBarCombo extends Component {

    getYears = () => {
        let categories = []
        for (let key in this.props.market.agentSplit) {
            categories.push(this.props.market.agentSplit[key]['year'])
        }
        categories = categories.sort( (a, b) => a - b)
        return categories
    }

    getAgents = () => {
        let agentCount = []
        //it's necessary to check explicitly by sorted years because there's no guarantee
        //that the years will come back in the correct order
        let years = []
        for (let key in this.props.market.agentSplit) {
            years.push(this.props.market.agentSplit[key]['year'])
        }
        years = years.sort((a,b) => a - b)
        years.forEach( year => {
            for (let key in this.props.market.agentSplit) {
                if (this.props.market.agentSplit[key]['year'] === year) {
                    agentCount.push(this.props.market.agentSplit[key]['num_of_agents_MLS'])
                }
            }
        })
        return agentCount
    }

    getGci = () => {
        let gci = []
        //it's necessary to check explicitly by sorted years because there's no guarantee
        //that the years will come back in the correct order
        let years = []
        for (let key in this.props.market.agentSplit) {
            years.push(this.props.market.agentSplit[key]['year'])
        }
        years = years.sort((a,b) => a - b)
        years.forEach( year => {
            for (let key in this.props.market.agentSplit) {
                if (this.props.market.agentSplit[key]['year'] === year) {
                    gci.push(this.props.market.agentSplit[key]['avg_agent_GCI'])
                }
            }
        })
        return gci
    }

    getTakeHome = () => {
        let takeHome = []
        //it's necessary to check explicitly by sorted years because there's no guarantee
        //that the years will come back in the correct order
        let years = []
        for (let key in this.props.market.agentSplit) {
            years.push(this.props.market.agentSplit[key]['year'])
        }
        years = years.sort((a,b) => a - b)
        years.forEach( year => {
            for (let key in this.props.market.agentSplit) {
                if (this.props.market.agentSplit[key]['year'] === year) {
                    takeHome.push(this.props.market.agentSplit[key]['avg_agent_GCI'] / this.props.market.agentSplit[key]['avg_rate'])
                }
            }
        })
        return takeHome
    }

    state = {
        config: {
            colors: ['#9FAD78', '#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6'],
            chart: {
                zoomType: 'xy',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            credits: {
                text: 'Source: MLS data used for # of agents; Trident data used for Finalized GCI and Agent Take Home',
                href: null
            },
            title: {
                text: ''
            },
            xAxis: [{
                categories: this.getYears(),
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                min: 0,
                labels: {
                    format: '$ {value}'
                },
                title: {
                    text: ''
                },
                opposite: true
            }, { // Secondary yAxis
                gridLineWidth: 0,
                min: 0,
                title: {
                    text: 'Agents'
                },
                labels: {
                    format: '{value}'
                }
            }, { // Tertiary yAxis
                gridLineWidth: 0,
                title: {
                    text: ''
                },
                labels: {
                    format: '',
                    enabled: false
                },
                opposite: true
            }],
            tooltip: {
                formatter: function () {
                    if (this.points[1] && this.points[2]) {
                        return this.points.reduce(function (s, point) {
                            console.log(point)
                            return s + '<br/>' + point.series.name + ': ' + (Math.round(point.y)).toLocaleString() ;
                        }, '<b>' + this.x + '</b>') + '<br/>Split: ' + ((this.points[1].y / this.points[2].y) * 100).toFixed(2) + '%'
                    } else {
                        return null
                    }

                },
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 80,
                verticalAlign: 'top',
                y: 55,
                floating: true,
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            series: [{
                name: 'Total agents',
                type: 'column',
                yAxis: 1,
                data: this.getAgents(),
                tooltip: {
                    valueSuffix: ''
                }
            }, {
                name: 'Avg. Agent Take Home',
                type: 'spline',
                yAxis: 0,
                data: this.getGci(),
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '',
                    valuePrefix: '$',
                    valueDecimals: 0
                },
                dashStyle: 'shortdot',
            }, {
                name: 'Avg. Finalized GCI',
                type: 'spline',
                tooltip: {
                    valuePrefix: '$',
                    valueDecimals: 0
                },
                data: this.getTakeHome(),
            }]
        }
        
    };
    
    render() {
      const chart  = React.createElement(ReactHighcharts, { config: this.state.config });

      return (
        <div className="linebar-chart">
            {chart}
        </div>
      );
    }
  }
  

  const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        currentPlan: state.current,
        market: state.market
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(LineBarCombo)