import React, { Component } from 'react';
import Nouislider from 'react-nouislider';
import './CapSelect.css';
import * as wNumb from 'wnumb';

class CapSelect extends Component {
    render() {
      return (
        <span className="cap-container">
        <div className="cap-select cap-spacing">
        <label className="switch-label">Cap</label>
        <div className="slider-container-edit">
            <Nouislider
            range={{min: 0, max: 55000}}
            start={[parseInt(this.props.cap)]}
            step={100}
            onSlide={evt=> this.props.setCap('cap',evt)}
            tooltips={[wNumb({
              decimals: 0,
              prefix: '$',
              thousand: ','
            })]}
                        />
        </div>
        </div>
        <div className="cap-select cap-spacing">
        <label className="segment-label cap-label">After Cap Split<em>(required)</em></label>
        <div className="slider-container-edit">
            <Nouislider
            range={{min: 0, max: 100}}
            start={[(this.props.afterCap * 100)]}
            onSlide={evt=> this.props.setCap('afterCap',evt)}
            tooltips={[wNumb({
              decimals: 0,
              suffix: '%'
            })]}
            />
        </div>
        </div>

        </span>

      );
    }
  }
  
  export {CapSelect}