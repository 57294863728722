import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import './AreaChart.css'

class AreaChart extends Component {


    handleData = (data) => {

        return data
    }

    componentDidUpdate() {
        let chart = this.refs.chart.getChart();
        console.log(this.props.gciDistribution.result)
        // let sorted = this.props.gciDistribution.result.sort((a,b) => a - b)
        if (this.props.gciDistribution.result != undefined) {
            let histData = this.props.gciDistribution.result
            let hist = []
            for (let i = 0; i < histData[0].length; i++) {
                hist.push([histData[1][i], histData[0][i]])
            }

            chart.series[0].update({
                name: 'Agent GCI Distribution',
                data: hist
            })
        }
        if (this.props.maxChartVal > 0) {
            chart.xAxis[0].update({
                max: this.props.maxChartVal
            }); 
        }
    }



    state = {
        config: {
            colors: ['#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6'],
            chart: {
                type: 'areaspline',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            title: {
                text: 'Agent GCI Distribution (across market)',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            xAxis: {
                max: 216000,
                min: 0
            },
            legend: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: "Number of Agents"
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.4,
                    marker:  {
                        enabled: false
                    }
                },
                marker: {
                    enabled: false
                }
            },
            series: [{
                name: 'Agent GCI Distribution',
                data: this.handleData(this.props.gciDistribution)
            }]
        },
    }


    
    render() {
        // const chart  = React.createElement(ReactHighcharts, { config: this.state.config });

      return (
        <div className="pie-chart">
            <ReactHighcharts config={this.state.config} ref="chart"></ReactHighcharts>
        </div>
      );
    }
  }
  
  export default AreaChart