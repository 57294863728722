import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../actions/plan_actions';
import * as currentPlanActions from '../../actions/current_plan_actions';
import * as marketActions from '../../actions/market_actions';
import './MarketOverview.css'
import MarketHeadline from './MarketHeadline/MarketHeadline';
import PieChart from '../Charts/PieChart/PieChart';
import LineBarCombo from '../Charts/LineBarCombo/LineBarCombo';
import LineChart from '../Charts/LineChart/LineChart';
import MarketCompetitors from './MarketCompetitors/MarketCompetitors';
import MarketSegmentation from './MarketSegmentation/MarketSegmentation';


// Displays the overall market after the user selectes the Market on the market Select page. 
// All the market data is mapped to the properties of the React component from the 
// current Market Reducer

class MarketOverview extends Component {

    componentDidMount() {
      //reload if there is no market data
      if (this.props.market.agentSplit == undefined) {
        this.props.history.push('/')
      }
    }

    render() {
      return (
        <div className="market-overview">
          <button onClick={ () => this.props.history.push('/plan-viewer')} className='btn waves-effect nav-button-text space-right'>Continue</button>
          <button onClick={ () => this.props.history.push('/')} className='btn waves-effect nav-button-text space-right' >Go Back</button>
          
          <div className="market-overview-container">
            <h1 className="market-overview-title"><span className="market-overview-light">Understand the</span> {this.props.market.name} <span className="market-overview-light"> Market</span></h1>
            <p className="market-overview-message">The charts below are based on current data.</p>
          </div>   
           <MarketHeadline/>  
          <section className="competitor-container">
            <h1  className="subtitle">Competitive dynamics and market details</h1>
          </section>  
          <section className="over-view-chart-container ">
            <PieChart data={this.props.market.marketoverview}/> 
            <LineChart data={this.props.market.marketoverview} fulldata={this.props.market.fullmarketdata} width={'50%'} />   
          </section> 
          <section className="competitor-container">
            <MarketCompetitors data={this.props.market.marketoverview}/>
          </section> 
          <section className="competitor-container end"> 
            <h1  className="subtitle">Agent Segmentation (Market)</h1>
            <p className="growth-header">Average 3-year growth is calculcated as the difference between the total future 3-year predicted and the last 3 years of sales.</p>
            <MarketSegmentation />
            <h1  className="subtitle">Average NRT Agent Split and Take Home</h1>
            <LineBarCombo />
          </section>
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
      return {
          plans: state.plans,
          current: state.current,
          market: state.market
      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          planAction: bindActionCreators(PlanActions, dispatch),
          currentAction: bindActionCreators(currentPlanActions, dispatch),
          marketAction: bindActionCreators(marketActions, dispatch)
      }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MarketOverview)