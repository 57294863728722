import React, { Component } from 'react';
import { Button } from 'react-materialize'
import './TierSelector.css';

class TierSelector extends Component {


    render() {

      const tier = this.props.forTier ? <label className="segment-label">Tiers <em>(required)</em></label> : ''
      const tierMessage = this.props.forTier ? <div className="tier-message">Minimum 2, maximum 8</div> : ''
      return (
        <div className="tier-container">
            {tier}
            <section className="segment-container">
                <Button onClick={() => this.props.setTier(false)} waves='light' className='tier-button' icon='remove'></Button>
                <div className='tier-current'>{this.props.tierCount}{this.props.suffix ? this.props.suffix : ''}</div>
                <Button onClick={() => this.props.setTier(true)}  waves='light' className='tier-button' icon='add'></Button>
            </section>
            {tierMessage}
        </div>
      );
    }
  }
  
  export {TierSelector}