import React, { Component } from 'react';
import './PlanCard.css';
import TierTable from '../Charts/TierTable/TierTable';
import PlanDetails from './PlanDetails/PlanDetails';
import { Icon, Input } from 'react-materialize';

class PlanCard extends Component {
    state = {
        showDetails: true,
        showCash: false
    }

    changeCashAdvance = () => {
        this.setState({
          showCash: !this.state.showCash
        })
    }

    render() {

      const removeIcon = (this.props.planId > 1 && this.props.remove) ?  <i className="material-icons close-icon" onClick={() => this.props.remove(this.props.planId)}>close</i> : ''
      const editIcon = (this.props.editableCard && !this.props.remove) ?  <i className="material-icons close-icon" onClick={() => this.props.startEdit()}>edit</i> : ''
      // let labelString = this.state.showCash ? "Recruiting premium: " + ((this.props.rp_final / 2) * 100).toFixed(1) + "% split and " + ((this.props.rp_final / 2) * 100).toFixed(1) + "% cash equivalent for " + this.props.title : "Recruiting premium: " + (this.props.rp_final * 100).toFixed(1) + "% split for " + this.props.title
      let labelString = "Recruiting premium: " + (this.props.rp_final * 100).toFixed(1)


      return (
        <div className={"row plan-card " + ((this.props.hidePlan !== undefined) ? (this.props.planId !== this.props.hidePlan ? 'hide-plan' : '') : '')}>
        {removeIcon} {editIcon}
            <section className="plan-card-right col s6">
            <h2 className="plan-card-title">{this.props.title}{this.props.editableCard}</h2>
            
            <TierTable editableCard={this.props.editableCard} gciToCap={this.props.gci_to_cap} recPrem={this.props.rp_final} showCash={this.state.showCash}  cap={this.props.cap} tiers={[...this.props.tiers]}></TierTable>
            <div className="tab-items rationale">RATIONALE</div>
            <p className="rationale-item">{this.props.rationale}</p>
            </section>
            <section className="plan-card-left col s6">
                <div className="row">
                    <div className="tab-items col s3 details">PLAN DETAILS : {this.props.structure} structure</div>
                </div>
                <PlanDetails {...this.props}/>
                <div>
                {/* <Input disabled={this.props.finalized} type='checkbox' checked={this.state.showCash}  onChange={() => this.changeCashAdvance()} label={labelString} /> */}
                {/* <p className='rationale-item' style={{textAlign: 'center'}}>Default recruiting premium for this plan: <b>{(this.props.rp_final * 100).toFixed(1)}%</b></p> */}
                </div>
                <div>

                </div>
            </section>
            <section></section>
        </div>
      );
    }
  }

  
  export default PlanCard