import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';
import * as progressActions from '../../../actions/progress_actions';
import './PlanCreator.css';
import { Row, Button} from 'react-materialize'
import 'nouislider/distribute/nouislider.css';
import { Switch, TierSelector, SegmentSelector, PlanSwitch } from './FormComponents';
import FormFunction from './FormComponents/FormFunctions';


// Plan Creator for developing plans on the Plan View page.

class PlanCreator extends Component {

    state = {
        payload : {
            tiers: 3,
            cap: true,
            franchiseFee: true,
            annualFee: false,
            recruitingTarget: 10,
            name: '',
            transactionFee: false,
            structure: "Freedom",
            segments: [],
            rationale: ''
        }
    }


    setPayloadType = FormFunction.setPayloadType.bind(this)
    setTier = FormFunction.setTier.bind(this)
    setFee = FormFunction.setFee.bind(this)

    toggleStructure = () => {
        if (this.state.payload.structure ==='Freedom') {
            this.setState({
                payload: {...this.state.payload,
                            cap: false,
                            structure: 'Accelerator'
                }
            })
        } else {
            this.setState({
                payload: {...this.state.payload,
                            cap: true,
                            structure: 'Freedom'
                }
            })
        }
    }

    selectSegment = (num) => {
        let arr = this.state.payload.segments.slice()
        let isPresent = arr.indexOf(num) > -1
  
        if (isPresent) {
            arr.splice(arr.indexOf(num), 1)

            this.setState({
                payload: {...this.state.payload,
                            segments: arr
                }
            })
        } else if (!isPresent) {
            arr.push(num)

            this.setState({
                payload: {...this.state.payload,
                            segments: arr
                }
            })
        }
    }

    setStates = () => {
        this.props.progressActions.startOptimize({ planName: 'test', inProgress: true})
    }

    render() {
      return (
        <div className={ 'plan-creator' + (this.props.extraClass ? ' creator-mover' : '')} >
            <div className="pc-top">
                <Button disabled={this.state.payload.segments.length === 0} waves='light' className='create-button-text' onClick={() => this.props.createPlan(this.state.payload)}>Create optimized plan</Button>
                {/* <PacmanLoader
                sizeUnit={"px"}
                size={20}
                color={'#36D7B7'}
                loading={this.props.loading}
                /> */}

                <h1 className="plan-creator-title">Add a Plan</h1>
                <input onChange={evt => this.setPayloadType(evt.target.value, 'name')} placeholder="Plan name" id="first_name" type="text" className="validate set-name" />

                <p className="pc-text-message ">Select the desired options below to create a custom plan.</p>
                <i className="material-icons close-icon" onClick={() => this.props.cancel(false)}>close</i>
            </div>
            <div className="pc-mid">
                <Row className="flex-row">
                    <SegmentSelector segmentData={this.props.market} segments={this.state.payload.segments} selectSegment={this.selectSegment}  />
                    <TierSelector tierCount={this.state.payload.tiers} forTier={true} setTier={this.setTier}/>
                    <PlanSwitch change={this.toggleStructure}  feeType={'structure'} label={(this.state.payload.structure + ' Structure')}></PlanSwitch>
                </Row>
            </div>
            <div className="pc-above-bottom">
                <h1 className="plan-creator-title">Additional Optimizations</h1>
                <section className='row flex-row'>
                    <Switch change={this.setFee} checked={this.state.payload.franchiseFee} feeType={'franchiseFee'} label={'Franchise Fee'}></Switch>
                    <Switch change={this.setFee} checked={this.state.payload.transactionFee} feeType={'transactionFee'} label={'Transaction Fee'}></Switch>
                    <Switch change={this.setFee} checked={this.state.payload.annualFee} feeType={'annualFee'} label={'Annual Fee'}></Switch>
                    <p className="target-message">Adding fees may significantly increase the optimization time.</p>
                </section>
            </div>
            <div className="pc-bottom">
                <label className="segment-label">Add a Rationale </label>
                <input onChange={evt => this.setPayloadType(evt.target.value, 'rationale')} placeholder="Rationale" type="text" className="validate set-name" />
            </div>
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
      return {
          plans: state.plans,
          current: state.current,
          progress: state.progress,
          market: state.market

      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          planAction: bindActionCreators(PlanActions, dispatch),
          currentAction: bindActionCreators(currentPlanActions, dispatch),
          progressActions: bindActionCreators(progressActions, dispatch)
      }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PlanCreator)