// for keeping track of the current user and sending JWT's for auth

export default(state = {username: null, token: null}, action) => {
    let newState = {...state}
    switch(action.type) {
        case 'set_new_user':
            newState.username = action.payload.user
            return newState
        case 'set_new_token':
            newState.token = action.payload.token
            return newState
        default:
            return state;
    }
}

