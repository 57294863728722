
// adds and removes plans to the state of the application.

export default(state = [], action) => {

    switch(action.type) {
        case 'set_default_plans':
            return [...action.payload.defaultPlans]
        case 'remove_plan':
            return state.filter( (item, index) => index != action.payload.planIndex)
        case 'edit':
            return state.map((item, index) => {
                if (index !== action.payload.idx) {
                // This isn't the item we care about - keep it as-is
                return item
                }
            
                // Otherwise, this is the one we want - return an updated value
                return {
                ...item,
                ...action.payload.changedPlan
                }
            })
        case 'clear_plans':
            return []
        case 'add_new_plan':
            return [...state, action.payload.newPlan]
        default:
            return state;
    }
}
