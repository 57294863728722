export const editPlan = (changedPlan, idx) => {
    console.log('making change to plan: ' + changedPlan)
    return {
        type: 'edit',
        payload: {
            idx: idx,
            changedPlan: changedPlan
        }
    }
}

export const addNewPlan = (plan) => {
    return {
        type: "add_new_plan",
        payload: {
            newPlan: plan
        }
    }
}

export const removePlan = (planIndex) => {
    return {
        type: "remove_plan",
        payload: {
            planIndex: planIndex
        }
    }
}

export const setDefaultPlans = (defaultPlans) => {
    return {
        type: "set_default_plans",
        payload: {
            defaultPlans: defaultPlans
        }
    }
}

export const clearPlans = (payload) => {
    return {
        type: "clear_plans",
        payload: {
            clearplans: null
        }
    }
}