import React, { Component } from 'react';
import { PulseLoader, PacmanLoader } from 'react-spinners';


const loading = {
    position: 'fixed',
    width: '100%',
    left:0,
    right:0,
    top:0,
    bottom:0,
    backgroundColor: 'rgba(255,255,255,0.7)',
    zIndex: '9999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

class Loading extends Component {
    render() {
      return  (
        <React.Fragment>
            {this.props.isLoading ? <div style={loading}>
            <PulseLoader
                sizeUnit={"px"}
                size={20}
                color={'grey'}
                loading={true}
                />
            </div> : null}
       </React.Fragment>
      );
    }
  }
  
  export default Loading