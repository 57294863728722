import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../actions/plan_actions';
import * as currentPlanActions from '../../actions/current_plan_actions';
import { withRouter} from 'react-router-dom';
import './Header.css'
import { ToastContainer, toast } from 'react-toastify';


// Header requires the router to be wrapped into it so that it doesn't display when on the home page.

class Header extends Component {

    render() {
        if (window.location.pathname === '/') return null;
        return ( 
            <section>
                <ToastContainer toastClassName='toast-message' autoClose={false}/>
                <div className="header-container">
                    <h2 onClick={() => this.props.history.push('/')} className="optimus-title global-title "> 
                        <div className="rectangle-top box-top"></div>
                        <div className="rectangle-bottom"></div>
                    Optimus</h2>
                </div>
            </section>
        )
    }
  }


  
  const mapStateToProps = (state) => {
      return {
          plans: state.plans,
          current: state.current
      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          planAction: bindActionCreators(PlanActions, dispatch),
          currentAction: bindActionCreators(currentPlanActions, dispatch)
      }
  }
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))