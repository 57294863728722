import React, { Component } from 'react';
import * as PlanActions from '../../../actions/plan_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';
import * as marketActions from '../../../actions/market_actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './MarketSegmentation.css'

// Displays the segments and the data associated with the segments.

class MarketSegmentation extends Component {

    makeRows = (segdata) => {
        let rows = []

        for (let key in segdata) {
            
            rows.push(
                <tr key={key}>
                    <td >{segdata[key]['Segment']}</td>
                    <td style={{textAlign: 'left'}}>{segdata[key]['Segment_Description']}</td>
                    <td>{(segdata[key]['Number of Agents']).toLocaleString()}</td>
                    <td>${Math.round(segdata[key]['Avg 3-Yr Growth*']).toLocaleString()}</td>
                    <td>{(segdata[key]['Est Avg Current Split'] * 100).toFixed(1)}%</td>
                    <td>{Math.round(segdata[key]['Avg Yrs in Industry']).toLocaleString()}</td>
                    <td>${Math.round(segdata[key]['Avg 2020 Production*']).toLocaleString()}</td>
                </tr>
            )
        }

        return rows
    }


    render() {
      let segmentrows = this.makeRows(this.props.market.segments)

      return (
        <div className="segmentation-table">
            <table ref="main">
                <thead>
                    <tr>
                        <td>Segment</td>
                        <td>Description</td>
                        <td># of Agents</td>
                        <td>Avg 3-Yr Growth</td>
                        <td>Est Avg Current Split</td>
                        <td>Avg Yrs in Industry</td>
                        <td>Avg 2020 Production</td>
                    </tr>
                </thead>
                <tbody>
                    {segmentrows}
                </tbody>
            </table>
        </div>
      );
    }
  }

const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        current: state.current,
        market: state.market
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketSegmentation)

