import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import './AreaSegmentChart.css'

class AreaSegmentChart extends Component {



    componentDidUpdate() {
        let chart = this.refs.chart.getChart();
        if (this.props.segmentDistribution != undefined) {
        console.log(this.props.gciDistribution.result)
        console.log(this.props.segmentDistribution)

        let histData = this.props.segmentDistribution
        let seg1 = []
        let seg2 = []
        let seg3 = []
        let seg4 = []
        for (let i = 0; i < histData[0].length; i++) {
            seg1.push([histData[1][i], histData[0][i]])
            seg2.push([histData[3][i], histData[2][i]])
            seg3.push([histData[5][i], histData[4][i]])
            seg4.push([histData[7][i], histData[6][i]])
        }

        chart.series[0].update({
            name: 'Segment 1 GCI Distribution',
            color: '#81d477',
            data: seg1
        })
        chart.addSeries({
            name: 'Segment 2 GCI Distribution',
            color: '#77c0d4',
            data: seg2
        })
        chart.addSeries({
            name: 'Segment 3 GCI Distribution',
            color: '#e03e3e',
            data: seg3
        })
        chart.addSeries({
            name: 'Segment 4 GCI Distribution',
            color: '#9F9F9F',
            data: seg4
        })
      }

      if (this.props.maxChartVal > 0) {
            chart.xAxis[0].update({
                max: this.props.maxChartVal
            }); 
        }
    }



    state = {
        config: {
            colors: ['#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6'],
            chart: {
                type: 'areaspline',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            title: {
                text: 'Distribution of Agents by 2020 GCI',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            legend: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: 'Number of Agents'
                }
            },
            xAxis: {
                max: 216000,
                min: 0
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.4,
                    marker:  {
                        enabled: false
                    }
                },
                marker: {
                    enabled: false
                }
            },
            series: [{
                name: 'Agent GCI Distribution',
                data: []
            }]
        },
    }


    
    render() {
        // const chart  = React.createElement(ReactHighcharts, { config: this.state.config });

      return (
        <div className="pie-chart">
            <ReactHighcharts config={this.state.config} ref="chart"></ReactHighcharts>
        </div>
      );
    }
  }
  
  export default AreaSegmentChart