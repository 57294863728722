import React, { Component } from 'react';
import './ImpactByPlan.css';
import { Button} from 'react-materialize'


class ImpactByPlan extends Component {

    createGradient (percent) {
        let color = percent === 100 ? '#cce5ff' : '#E7EDF6'
        return {
            backgroundImage: `linear-gradient(to right, ${color}, ${color} ${percent}%, transparent ${percent}%, transparent ${percent}%)`
        }
    }

    reduceDecimals(val, valType) {
      let isSplit = valType.includes('Split')
      let isMargin = valType.includes('Margin')
      if (isSplit) {
        return (val * 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%'
      } else if (isMargin) {
        return (val).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%'
      } else {
        return '$' + val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    }

    setSegment (field, val) {
      let segments = {...this.state.segments}
      segments[field] = parseInt(val[0]);
      this.setState({
          segments
      })
    }

    render() {
        let header = ''
        let tableData = []
        let headDataTh = null;
        if(this.props.impact.best) {
          console.log(this.props.impact)
            let headData = [];
            for(let key in this.props.impact) {
              if(key.indexOf('Econ') == -1 && key.indexOf('best') == -1) headData.push(key)
            }
            console.log('head data: ')
            console.log(headDataTh)

            headDataTh = headData.map ((val, idx) => (<th key={idx}>{val}</th>))
            header = (<tr className="table-header"><th></th>{headDataTh}<th>Optimal Machine Generated</th></tr>)

            let uniq = [...new Set(this.props.impact['Econ Impact'])]
            console.log(uniq)

            tableData = uniq.map( (uniqVal, idx)=> {
                return <tr key={idx} className="table-body-row">
                          <td>
                              {uniqVal}
                          </td>
                          {headData.map((val, fdx) => {
                              return <td key={fdx} >{this.reduceDecimals(this.props.impact[val][idx], uniqVal)}</td>
                          }
                          )}
                          <td>
                              {this.reduceDecimals(this.props.impact['best'][idx], uniqVal)}
                          </td>
                      </tr>
            })
        }

      let maxValues = [];
      if(this.props.context) {
        if(this.props.context['Number of Agents']) {
          maxValues = this.props.context['Number of Agents']
        }
      }
      // console.log(this.props)
      // console.log(this.props.numAgents)
      return (

        <div className="optimality">
            <h1 className="title">Plan impact given recruiting assumptions
            </h1>
            <div className="target-container">
            <p className="target-message">The initial assumed recruiting targets are prepopulated at Net 10%. You can adjust the assumptions by inputing new values and confirming them here. The impact is calculated by assuming all recruited agents select the plan that optimizes their take home pay.</p>
            <section style={{ display: 'flex' }}>
            <div className="segment-recruit-ctn agent-label">
              <div style={{borderBottom: '1px solid grey', paddingTop: '19px', paddingBottom: '7px'}}><label>Recruited Agents</label></div><div style={{paddingTop: '6px'}}><label>Total non-NRT Agents</label></div>
            </div>
              <div className="segment-recruit-ctn">
                <label>Segment 1</label>
                <input type="number" className="recruit-input" onChange={(e) => this.props.setTargets('1', e.target.value)} value={this.props.recruits ? this.props.recruits[1] : 0} ></input>
                <label><span className='darken'>{maxValues[0]}</span></label>
              </div>
              <div className="segment-recruit-ctn">
                <label>Segment 2</label>
                <input type="number" className="recruit-input" onChange={(e) => this.props.setTargets('2', e.target.value)} value={this.props.recruits ? this.props.recruits[2] : 0} ></input>
                <label><span className='darken'>{maxValues[1]}</span></label>
              </div>
              <div className="segment-recruit-ctn">
                <label>Segment 3</label>
                <input type="number" className="recruit-input" onChange={(e) => this.props.setTargets('3', e.target.value)} value={this.props.recruits ? this.props.recruits[3] : 0} ></input>
                <label><span className='darken'>{maxValues[2]}</span></label>
              </div>
              <div className="segment-recruit-ctn">
                <label>Segment 4</label>
                <input type="number" className="recruit-input" onChange={(e) => this.props.setTargets('4', e.target.value)} value={this.props.recruits ? this.props.recruits[4] : 0} ></input>
                <label><span className='darken'>{maxValues[3]}</span></label>
              </div>
              <Button disabled={this.props.editTargets}waves='light' className='create-button-text' style={ {marginTop: '30px'}} onClick={() => this.props.getTargets()}>Confirm</Button>
            </section>
            </div>
            <p style={{margin: '0 0 0 50%'}}><label>Current NRT agents in region: <span className='darken'>{this.props.numAgents ? this.props.numAgents[3]['num_of_agents_MLS'] : ''}</span></label> &nbsp;&nbsp;&nbsp;&nbsp; <label>Agent attrition in region: <span className='darken'>{this.props.attrition}</span></label></p>


     
            <section className="optimality-segment ">
            <div className="optimality-table">
                 <table>
                     <thead>
                     <tr className="table-header"><th></th>{headDataTh}<th>Agent Best Plan</th></tr>
                     </thead>
                     <tbody>
                       {tableData}
                     </tbody>
                 </table>
             </div>
            
            </section>
        </div>

      );
    }
  }
  
  export default ImpactByPlan

