import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';
import './AgentLookup.css';
import { Row, Input, Button } from 'react-materialize';


class AgentLookup extends Component {

    state = {
        name: '',
        agentList: []
    }

    handleChange = event => {
        this.setState({
          name: event.target.value,
        });
    };

    handleAgentSearch = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let tempName = (this.state.name).toLowerCase()
        console.log(tempName)
        this.setState({
            name: '',
            agentList: []
        })

        let filteredAgents = this.props.agents.filter( agent => {
            let agentFull = (agent['Agent First Name'] + ' ' + agent['Agent Last Name']).toLowerCase()
            if (agentFull.includes(tempName)) {
                console.log(agent)
                return true
            } else {
                return false
            }
        })
        this.setState({
            agentList: filteredAgents
        })
    }

    renderAgent = () => {
      if (this.state.agentList.length === 0) {
        return []
      }
      console.log(this.state)
      let agents = this.state.agentList.map( agent => {
          let fullName = agent['Agent First Name'] + ' ' + agent['Agent Last Name']
          
          return (
              <div key={fullName} className='agent-list'>
                  <p className="agent-text"><span className='agent-full-name'>{fullName}</span> - <i>{agent['Current Brokerage']}</i></p>
                  <p className="agent-text">Segment: <b>{agent['Segment']}</b></p>
                  <p className="agent-text">2020 Actual GCI: <b>${(Math.round(agent['2020 Actual GCI'])).toLocaleString()}</b></p>
                  <p className="agent-text">2021 Estimated GCI: <b>${(Math.round(agent['2021 Estimated GCI'])).toLocaleString()}</b></p>
                  <p className="agent-text">Best Plan: <b>{agent['Best Plan']}</b></p>
              </div>
          )
      })
      console.log(agents)
      return agents
    }
    

    render() {
      let agents = this.renderAgent()

      return (
        <div>
            <section className="agent-lookup-container">
                <h1  className="subtitle">Agent Quick-Lookup</h1>
                <form>
                    <Row>
                        <Input label="Agent Name" s={6} onChange={this.handleChange} value={this.state.name}/>
                        <button onClick={this.handleAgentSearch} className='btn waves-effect nav-continue' id="search-btn">Search</button>
                    </Row>
                </form>
                <div className='results-container'>
                    {agents}
                    {/* {this.state.agentList.length > 0 ? {agents} : <p>Sorry, that agent doesn't appear to be listed in the MLS database.</p>} */}
                </div>
            </section>  
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
      return {
          plans: state.plans,
          current: state.current
      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          planAction: bindActionCreators(PlanActions, dispatch),
          currentAction: bindActionCreators(currentPlanActions, dispatch)
      }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AgentLookup)