export const startOptimize = (state) => {
    return {
        type: 'optimizing_plan',
        payload: {
            state: state
        }
    }
}


export const endOptimize = (state) => {
    return {
        type: 'finish_optimization',
        payload: {
            state: state
        }
    }
}


