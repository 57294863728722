import React, { Component } from 'react';
import './Optimality.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as marketActions from '../../../actions/market_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';
import { Dropdown, trigger, NavItem, Button } from 'react-materialize';

class Optimality extends Component {

    state = {
        currentSegment: 0
    }

    createGradient (percent) {
        let color = percent === 100 ? '#cce5ff' : '#E7EDF6'
        return {
            backgroundImage: `linear-gradient(to right, ${color}, ${color} ${percent}%, transparent ${percent}%, transparent ${percent}%)`
        }
    }

    renderHeaders = (data) => {
        let keys = this.props.plans.map( plan => {
            if (data[plan.title]){
                return plan.title
            } else {
                return null
            }
        })
        keys = keys.filter( key => key != null)
        keys.unshift('')
        // keys.push('Optimal Machine Generated')
        let keyRows = keys.map( (key, idx) => {
           return <th key={idx}>{key}</th>
        })
        return keyRows
    }

   


    renderData = (data) => {
        if (data['Accelerator Plan'] == undefined) {
            return null
        }
        let keys = this.props.plans.map( plan => {
            return plan.title
        })
        keys.unshift('Econ Impact')
        
        let segments = [[],[],[],[]];

        for(var j = 0; j < data['Econ Impact'].length/4; j ++) {
            segments[0][j] = 0 + (j * 4)
            segments[1][j] = 1 + (j * 4)
            segments[2][j] = 2 + (j * 4)
            segments[3][j] = 3 + (j * 4)
        }
        

        // console.log(segments)
        let planRows = segments[this.state.currentSegment].map( (seg, idx) => {
            return (
                <tr key={idx} className="table-body-row">
                    {keys.map( (key, idx) => {
                        if (data[key]){
                            let tdData = data[key][seg]
                            if (typeof data[key][seg] == 'number' && data[key][seg] > 1) {
                                tdData = '$' + data[key][seg].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            } else if (typeof data[key][seg] == 'number') {
                                if (data['Econ Impact'][seg] == '2019 EBITDA' || data['Econ Impact'][seg] == '2019 EBITDAR') {
                                    tdData = '$' + data[key][seg].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                } else {
                                    tdData = ((data[key][seg]) * 100).toFixed(0) + '%'
                                }
                            }
                            let bestClass = ""
                            if (data[key][seg] === data['best'][seg] && data['Econ Impact'][seg] === '2019 Agent Take Home Dollar') {
                                bestClass = "highlight"
                            }
                            return (
                                <td className={bestClass} key={idx}>{tdData}</td>
                            )
                        }
                    })}
                </tr>
            )
        })

        return planRows
    }

    changeSegment = (val) => {
        let seg = this.state.currentSegment
        seg  = seg + val
        seg = seg > 3 ? seg = 3 : seg
        seg = seg < 0 ? seg = 0 : seg
        this.setState({
            currentSegment: seg
        })
        this.props.changeSeg(seg)
    }

    render() {
        const tableData = this.renderData(this.props.segmentImpact)
        const tableHeaders = this.renderHeaders(this.props.segmentImpact)


      return (
        <div className="optimality">
            <h1 className="title">Plan Comparison for Typical Agent by Segment</h1>
            <p className="optimality-message">Impacts displayed are for the median agent in the selected agent segment with highest estimated take home dollar highlighted.</p>
            <div>
                <button className="btn waves-effect seg-button-text" onClick={() => this.changeSegment(-1)}>
                <i className="material-icons">chevron_left</i>
                </button>
                    <span style={{fontStyle: 'italic', color: '#828EA1'}}>Current Segment: { this.state.currentSegment + 1}</span>
                <button className="btn waves-effect seg-button-text" onClick={() => this.changeSegment(1)}>
                <i className="material-icons">chevron_right</i>
                </button>
            </div>
            <section className="optimality-segment ">
            <div className="optimality-table">
                 <table>
                     <thead>
                         <tr className="table-header">
                         </tr >
                           <tr className="table-header">
                                {tableHeaders}
                           </tr>
                     </thead>
                     <tbody>
                       {tableData}
                     </tbody>
                 </table>
             </div>
            
            </section>
        </div>

      );
    }
  }
  

  const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        currentPlan: state.current,
        market: state.market
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Optimality)