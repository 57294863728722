

// sets the market data globaly
const initialState = {
    name: "",
    code: "",
    fullmarketdata: [],
    marketoverview: {
        SUM_SalePrice__c: 0,
        AVG_SalePrice__c: 0,
        Count__c: 0
    }
}


export default(state = initialState, action) => {
    let newstate = {...state,
                        fullmarketdata: [
                            ...state.fullmarketdata
                        ],
                        marketoverview: {
                            ...state.marketoverview
                        }
                    }
    switch(action.type) {
        case 'select_new_market':
            newstate.name = action.payload.market
            newstate.code = action.payload.code
            return newstate
        case 'set_market_overview':
            newstate.marketoverview = {...action.payload.marketoverview}
            return newstate
        case 'set_segments':
            newstate.segments = {...action.payload.segments}
            return newstate
        case 'clear_market':
            return newstate
        case 'set_agent_split':
            newstate.agentSplit = {...action.payload.agentSplit}
            return newstate
        case 'set_full_market_data':
            newstate.fullmarketdata = [...action.payload.fullmarketdata]
            return newstate
        default:
            return state;
    }
}