import React, { Component } from 'react';
import './Switch.css';

class Switch extends Component {
    render() {
      // console.log(this.props.checked)
      return (
        <div className="switch">
        <label className="switch-label">
          <input onChange={() => this.props.change(this.props.feeType)} type="checkbox" checked={this.props.checked}/>
          <span className="lever"></span>
          {this.props.label}
        </label>
      </div>
      );
    }
  }
  
  export {Switch}