import plans from './planReducer';
import current from './currentPlanReducer';
import market from './currentMarketReducer';
import progress from './planProgress';
import user from './userReducer';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
    plans,
    current,
    market,
    progress,
    user
})
export default rootReducer