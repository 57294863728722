export const setUser = (user) => {
    return {
        type: "set_new_user",
        payload: {
            user: user
        }
    }
}

export const setToken = (token) => {
    return {
        type: "set_new_token",
        payload: {
            token: token
        }
    }
}