import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../actions/plan_actions';
import * as marketActions from '../../actions/market_actions';
import * as currentPlanActions from '../../actions/current_plan_actions';
import './Finalized.css';
import '../PlanCard/PlanCard';
import PlanCard from '../PlanCard/PlanCard'; 


// Finalized view gives the user the option to print all the plans they have created throughout the planning process
// Plans are displayed both with and without recruitment premium
//<Finalized>

class Finalized extends Component {

    state = {
        createPlan: false,
        planPayload: {},
        editPlan : false
    }


    togglePlans = (idx) => {
      if(this.state.editPlan === false) this.props.currentAction.selectCurrent(idx)
    }

    toggleEditPlan = () => {
      this.setState({
        editPlan : !this.state.editPlan
      });
    }

    getEditedPlanValues = (editedPlan) => {
      console.log('Edited plan', editedPlan)
      this.props.planAction.addNewPlan(editedPlan)
      this.toggleEditPlan()
    }

    componentDidMount() {
      if (this.props.market.agentSplit == undefined) {
        this.props.history.push('/')
      }
    }

    getPrint () {
      window.print();
    }


    render() {
      console.log(this.props.plans)
      const Cards = this.props.plans.map((item, index) => {

          return <span key={index} >
            {/* <PlanCard recPrem={false} finalized={true} planId={index} startEdit={this.toggleEditPlan} remove={this.removePlan} {...item}></PlanCard> */}
            <PlanCard key={index} planId={index} remove={this.removePlan} {...item}></PlanCard>
          </span>
      })

      const PlanHeaders = this.props.plans.map((item, idx) => {
          return ( 
          <div className={"plan-btn " + (idx === this.props.currentPlan ? 'selected' : '')}  key={idx} onClick={() => this.togglePlans(idx)}>
            <div>
              {item.title}
            </div>
          </div>
          )
      })

      return (
        <div className="summary">
            <button onClick={ () => { this.getPrint()}} className='btn waves-effect nav-button-text space-right'> Print<i className="material-icons" style={{transform: 'translate(10px, 2px)'}}>print</i></button>
            <button onClick={ () => { this.props.history.push('/summary')}} className='btn waves-effect nav-button-text space-right'>Go Back</button>

            <div className="summary-container">
                <h1 className="plan-create-title"><span className="plan-create-light">Comission Plans for</span> {this.props.market.name}</h1>
                {Cards}
                {/* <SegmentImpact />
                <OfficeImpact/> */}
                {/* <Optimality segmentImpact={dummySegmentImpact}/> */}
            </div>
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    
    return {
        plans: state.plans,
        currentPlan: state.current,
        market: state.market
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Finalized)