import React, { Component } from 'react';
import './App.css';
import MarketSelect from './components/MarketSelect/MarketSelect';
import MarketOverview from './components/MarketOverview/MarketOverview';
import PlanViewer from './components/PlanViewer/PlanViewer';
import Summary from './components/Summary/Summary';
import Header from './components/Header/Header';
import { Route, Switch } from "react-router-dom";
import Finalized from './components/Finalized/Finalized';
import './noUiSliderOverride.css'
import 'react-toastify/dist/ReactToastify.css';
 

class App extends Component {

  // Standard React Router Header does not appear on '/' route
  render() {
    return (
      <div id="main-view" ref={this.textInput}>
      <Header/> 
      <Switch>
        <Route exact path="/" component={MarketSelect} />
        <Route path="/market-overview" component={MarketOverview} />
        <Route path="/plan-viewer" component={PlanViewer} />
        <Route path="/summary" component={Summary} />
        <Route path="/finalized" component={Finalized}/>
      </Switch>
      </div>

    );
  }
}

export default App;
