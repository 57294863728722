import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import './PieChart.css'


// Displays the market Share of the different brokerages based off market data.

class PieChart extends Component {

    handleData = (data) => {
        let processedData = []
        let sum = 0
        if (data) {
            for (let i = 1; i < 10; i++) {
                let tmpName = "Top_" + i + "_brand"
                let tmpShare = "Top_" + i + "_MarketShare"
                sum += data[tmpShare]
                processedData.push({
                    name: data[tmpName],
                    y: data[tmpShare]
                })
            }
            let othershare = 1 - sum
            processedData.push({
                name: "Other",
                y: othershare
            })
        }

        return processedData
    }

    state = {
        data: this.props.data,
        config : {
            colors: ['#9FAD78', '#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6', '#C1CAA9', '#4F6C4A', '#DDD5DE', '#E6EBE0'],
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: 450
            },
            credits: {
                text: 'Source: MLS data used for all competitor information',
                href: null
            },
            
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    // Make the labels less space demanding on mobile
                    chartOptions: {
                        xAxis: {
                            labels: {
                                formatter: function () {
                                    return this.value.charAt(0);
                                }
                            }
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -2
                            },
                            title: {
                                text: ''
                            }
                        }
                    }
                }]
            },
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                x: 0,
                y: 100
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: this.handleData(this.props.data)
            }]
        }
    };


    
    render() {
      const chart  = React.createElement(ReactHighcharts, { config: this.state.config });

      return (
        <div className="pie-chart">
            {chart}
        </div>
      );
    }
  }
  
  export default PieChart