import React, { Component } from 'react';
import './SegmentSelector.css';
import ReactTooltip from 'react-tooltip'

class SegmentSelector extends Component {

    makeSegments = (numSegs) => {
      let segmentDivs = []

      for(let i = 0; i < numSegs; i++) {
        let isPresent = this.props.segments.indexOf(i + 1) > -1
        let selected = isPresent ? ' selected' : ''

        segmentDivs.push(
        <button key={i} data-tip={this.props.segmentData.segments[i]['Segment_Description']} onClick={()=> this.props.selectSegment((i + 1))}
                className={'segment-item waves-effect' + selected}>
                {i + 1}
         </button>
        )
      }
      return segmentDivs
    }

    render() {
      // console.log(this.props.segmentData.segments)
      const segmentDivs = this.makeSegments(4)

      return (
        <div>
        <label className="segment-label">Target Segments <em>(required)</em></label>
        <section className="segment-container">
            {segmentDivs}
        </section>
        <ReactTooltip place="top" type="info" effect="solid"/>
        </div>
      );
    }
  }
  
  export {SegmentSelector}