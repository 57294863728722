import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import './LineChart.css'


// displays the last five years of market share by Brokerage


class LineChart extends Component {

    handleCategories = (data) => {
        // console.log(data)
        let year = new Date().getFullYear()
        let startYear = year - 5
        let processedCategories = []
        for (let i = startYear; i <= year; i++) {
            processedCategories.push(i)
        }
        return processedCategories
    }

    handleData = (data) => {

        if (data && data[0] != undefined) {   
            let currYear = data[0]
            let topBrands = []
            //construct the chart series from the passed data
            for (let i = 1; i < 11; i++) {
                let nameStr = `Top_${i}_brand`
                let shareStr = `Top_${i}_MarketShare`
                let brandData = []
                for (let j = 0; j < 10; j++) {
                    let rounded = Math.round(data[j][shareStr] * 10000) / 100
                    brandData.unshift(rounded)
                }
                topBrands.push({
                                showInLegend: false,
                                name: currYear[nameStr],
                                data: brandData
                            })
            }
            //retrieve only the last 5 years' worth of data
            return topBrands.map(item =>  {
                item.data = item.data.slice(-5)
                return item
            })
        } else {
            return []
        }
    }


    state = {
        config : {
            colors: ['#9FAD78', '#2E3C47', '#7591A7', '#BCCAD6', '#9F9F9F', '#E7EDF6', '#C1CAA9', '#4F6C4A', '#DDD5DE', '#E6EBE0'],
            chart: {
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }
            },
            xAxis: {
                categories: this.handleCategories(this.props.data)
            },
            series: this.handleData(this.props.fulldata),
            title: {
                text: 'Market Share Over Time',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#828EA1'
                }

            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: "% of total sales"
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            }
        }
    };
    
    render() {
      const chart  = React.createElement(ReactHighcharts, { config: this.state.config });

      return (
        <div className="line-chart" style={ {width: this.props.width} }>
            {chart}
        </div>
      );
    }
  }
  
  export default LineChart