import React, { Component } from 'react';
import { TierSelector } from '../../../../PlanViewer/PlanCreator/FormComponents';

import './FranchiseFeeSelect.css';

class FranchiseFeeSelect extends Component {
    render() {
      
      return (
        <div className="franchise-select franchise-spacing">
        <label className="switch-label">Franchise Fee</label>
        <span>
            <TierSelector suffix='%' tierCount={this.props.franchiseFee} setTier={this.props.setFee}/>
        </span>
        </div>
      );
    }
  }
  
  export {FranchiseFeeSelect}