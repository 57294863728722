import React, { Component } from 'react';
import '../PlanCard.css';
import './PlanDetails.css';

class PlanDetails extends Component {
  render() {
      let segments = this.props.segments ? this.props.segments.sort( (a,b) => a - b).join(', ') : '-'

      return (
        <div className="col s12 plan-detail-container">
          <section className="row row-edits">
            <div className="col">
              <span className="plan-detail-values">{segments}</span>
              <span className="plan-detail-labels">Target Segments</span>
            </div>
            <div className="col">
              <span className="plan-detail-values">{this.props.cap ? '$' + Math.round(this.props.cap[0] / 1000) + 'K' : '-'}</span>  
              <span className="plan-detail-labels">Cap</span>
            </div>
            <div className="col">
              <span className="plan-detail-values">{this.props.cap ? (this.props.cap[1] * 100).toFixed(0) + '%' : '-'}</span>
              <span className="plan-detail-labels">Cap rate</span>
            </div>
          </section>
          <section className="row row-edits">
          <div className="col">
              <span className="plan-detail-values">{this.props.franchise_fee * 100}%</span>
              <span className="plan-detail-labels">Franchise Fee</span>
            </div>
            <div className="col">
              <span className="plan-detail-values">${Math.round(this.props.transaction_fee)}</span>
              <span className="plan-detail-labels">Transaction Fee</span>
            </div>
            <div className="col">
              <span className="plan-detail-values">${Math.round(this.props.annual_fee)}</span>
              <span className="plan-detail-labels">Annual Fee</span>
            </div>
          </section>
        </div>
      );
    }
  }
  
  export default PlanDetails