const FormFunction = {

    setPayloadType: function (val, fieldType) {
        let payload = {...this.state.payload}
        payload[fieldType] = val
        this.setState({
            payload
        })
    },

    setTier : function (up) {
        let payload = {...this.state.payload}
        up ? payload.tiers++ : payload.tiers--
        if(payload.tiers > 8) payload.tiers = 8
        if(payload.tiers < 2) payload.tiers = 2
        this.setState({
            payload
        })
    },

    setFee: function (feeType) {
        let payload = {...this.state.payload}
        payload[feeType] = !payload[feeType]
        console.log(payload)
        this.setState({
            payload
        })
    },

    // sendPayload: function(payload) {
    //     console.log(payload)
    //     return new Promise((resolve, reject) => {
    //       resolve('success')
    //     })
    // }
}

export default FormFunction;