import React, { Component } from 'react';
import './TierTable.css';



// Renders the plans tiers, splits, and possily GCI
// props include 
    // inEditStage -- is it currently and editable view
    // tiers - list of tiers
    // recPrem - the recruiting premium
    // gci - Gross commisionalbe income


class TierTable extends Component {

    render() {    
        console.log(this.props)

        const editIcon = (this.props.edit) ?  <i className="material-icons close-icon" >edit</i> : ''
        const tableData = this.props.tiers.map( (tier, index) => {
            let capBump
            if (this.props.showCash && this.props.cap) {
                capBump = this.props.cap[0] / (1 - (tier.split - (this.props.recPrem / 2)))
            } else if (this.props.cap) {
                capBump = this.props.gciToCap[index]
                if (this.props.gci_to_cap != undefined) {
                    capBump = this.props.gci_to_cap[index]
                }
            }

        console.log(tier)
        let cleanRecPrem = (tier.tier_rp * 100).toFixed(1) + '%'
        let recPremVal = Number((tier.tier_rp * 100).toFixed(1))

         return <tr key={tier.tier} className="table-body-row">
             <td>
                 {tier.tier}
             </td>
             <td>
                 ${tier.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
             </td>
             <td>
                 {tier.max ? '$' + tier.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
             </td>
             <td>
                 {this.props.showCash ? Math.round((tier.split - (this.props.recPrem / 2)) * 100) : Math.round((parseFloat(tier.split)) * 100)}% {editIcon}
             </td>
             <td>
                 {/* {(this.props.recPrem * 100).toFixed(1)}% */}
                 {this.props.inEditStage ? <input style={{margin: 0, width: '50%', textAlign: 'center'}} type='number' onChange={(e) => this.props.setRecPrem(e, (tier.tier - 1))} value={recPremVal}></input> : cleanRecPrem}
             </td>
             <td>
                 <b>{((tier.tier_rp + tier.split) * 100).toFixed(1)}%</b>
             </td>
                {this.props.cap ? <td>${Math.round(capBump).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td> : null}
            
         </tr>
     })



      return (
          <div className="tier-table">
         
              <table>
                  <thead>
                        <tr className="table-header">
                            {/* <th></th> */}
                            <th colSpan='3'></th>
                            {/* <th></th> */}
                            <th>Split to</th>
                            <th>Recruiting</th>
                            <th>Total</th>
                            {this.props.cap ? <th>Bump</th> : null}
                        </tr >
                        <tr className="table-header">
                            <th>Tier</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Retain</th>
                            <th>Premium</th>
                            <th>Split</th>
                            {this.props.cap ? <th>to GCI</th> : null}
                        </tr>
                  </thead>
                  <tbody>
                    {tableData}
                  </tbody>
              </table>
          </div>
      );
    }
  }
  
  export default TierTable