import React, { Component } from 'react';
import './ToastMessage.css';
import { PulseLoader } from 'react-spinners';


class ToastDoneMessage extends Component {


  render() {
    return (
      <div className="toast-message">
           <div className="optimizing">Optimizing Complete</div> 
      </div>
    );
  }
}

export default ToastDoneMessage