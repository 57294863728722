// select new plan updates the current plan on the summary view

export default(state = 0, action) => {
    switch(action.type) {
        case 'select_new_plan':
            return action.payload.idx
        default:
            return state;
    }
}

