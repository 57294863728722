import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../../actions/plan_actions';
import * as currentPlanActions from '../../../actions/current_plan_actions';
import './EditPlan.css';
import { Button} from 'react-materialize'
import 'nouislider/distribute/nouislider.css';
import { CapSelect, FranchiseFeeSelect, SimpleSelect } from './EditComponents';
import TierTable from '../../Charts/TierTable/TierTable';

// Allows user to edit the splits, tiers, name, and fees of a plan. This is then sent back to the
// Summary view to determine the market impact

class EditPlan extends Component {


    constructor(props) {
      super(props)
      
      let planCopy = {...this.props.plan,
                          segments: this.props.plan.segments ? [...this.props.plan.segments] : null,
                          tiers: JSON.parse(JSON.stringify(this.props.plan.tiers))
                      }

      this.state = {
        ...planCopy,
        titleChange: false,
        orginalName: this.props.plan.title
      }
    }

    componentDidMount() {
      console.log('======== CHECK STATE ===========')
      console.log(this.props.plan)
      console.log(this.state)
    }

    setTierOrSplitValue (tierOrSplit, val) {
      if(tierOrSplit == 'tier') {
        let tiers = this.setTiers(val, this.state.tiers)
        this.setState({tiers: tiers})
      } else if(tierOrSplit == 'split') {
        let tiers = this.setSplit(val, this.state.tiers)
        this.setState({tiers: tiers})
      } else {
        this.setState ({
          [tierOrSplit] : Number(val[0])
        })
      }
    }

    setTiers(tiers, planTiers) {
      return planTiers.map((tier,idx)=> {
          if(tier.tier == 1) {
            tier.max = Number(tiers[idx])
            return tier
          } else if(idx == tiers.length) {
            tier.min = Number(tiers[idx - 1]) + 1
            return tier
          } else {
            tier.min = Number(tiers[idx - 1]) + 1
            tier.max = Number(tiers[idx])
            return tier
          }
      })
    }

    setFranchiseFee (up) {
      let franchise_fee = this.state.franchise_fee * 100
      up ? franchise_fee++ : franchise_fee--
      if(franchise_fee > 6) franchise_fee = 6
      if(franchise_fee < 0) franchise_fee = 0
      franchise_fee = franchise_fee/100
      this.setState({
          franchise_fee : franchise_fee
      })
    }

    setRecPrem = (e, tierIdx) => {
      console.log(Number(e.target.value))
      console.log(tierIdx)
      console.log(this.state)
      let currentTiers = [...this.state.tiers]
      currentTiers[tierIdx]['tier_rp'] = Number(e.target.value / 100)
      console.log(currentTiers)
      console.log(this.state.tiers)

      this.setState({
        tiers: [...currentTiers]
      }, () => console.log(this.state))

    }

    setSplit(splits, planTiers) {
      return planTiers.map((tier,idx)=> {
        tier.split = parseInt(splits[idx])/100;
        return tier;
      })
    }

    setCapAndAfterCap(type, val) {
      let cap = [...this.state.cap]
      if(type === 'cap') {
        cap[0] = Number(val[0])
      } else {
        cap[1] = Number(val[0])/100
      }
      this.setState({
        cap: cap
      })
    }

    setName (name) {
      this.setState({ 
        title: name,
        titleChange: !this.state.titleChange ? (!this.state.titleChange) : this.state.titleChange
      }) 
    }

    setRationale (rationale) {
      this.setState({
        rationale: rationale
      }) 
    }

    testSimilarity = (a,b) => {
      return a.split(" ").join('') === b.split(" ").join('')
    }



    setFranchiseFee = this.setFranchiseFee.bind(this)
    setCapAndAfterCap = this.setCapAndAfterCap.bind(this)
    setName = this.setName.bind(this)
    setRationale = this.setRationale.bind(this)

    render() {
      let CapChange =  this.state.cap !== null  ? <CapSelect cap={this.state.cap[0]} afterCap={this.state.cap[1]} setCap={this.setCapAndAfterCap} /> : '';
      let tierRange =  this.state.tiers.filter(val => val.max).map(val => parseInt(val.max));
      let splitRange =  this.state.tiers.map(val => val.split * 100);

      return (
        <div className="edit-plan edit-plan-container" >
          <div className="pc-top">
            <h1 className="edit-plan-title">Edit Plan - {this.state.title} {this.state.titleChange ? '' : <span style={{color: 'lightseagreen'}}>(new name required to save edits)</span>}</h1>
            <input style={{width: '400px'}} onChange={evt => this.setName(evt.target.value)} value={this.state.title} id="first_name" type="text" className="validate set-name" />
            <span className="franchise-fee-message">
              <p className="pc-text-message">Adjust the desired options below to edit the generated plan.</p>
              <FranchiseFeeSelect franchiseFee={(this.state.franchise_fee * 100) } setFee={this.setFranchiseFee}/>
            </span>
            <i className="material-icons close-icon" onClick={() => this.props.cancel()}>close</i>
          </div>
          <div className="pc-bottom">
            <section >
              {CapChange}
              <span className="cap-container">
                <SimpleSelect setVal={this.setTierOrSplitValue.bind(this,'transaction_fee')} value={[parseFloat(this.state.transaction_fee)]} max={750} label={'Transaction Fee'}></SimpleSelect>
                <SimpleSelect setVal={this.setTierOrSplitValue.bind(this,'annual_fee')} value={[parseFloat(this.state.annual_fee)]} max={20000} label={'Annual Fee'}></SimpleSelect>
              </span>
              <section className="edit-tier">
                <TierTable {...this.state} inEditStage={true} setRecPrem={this.setRecPrem} gciToCap={this.state.gci_to_cap} recPrem={this.state.rp_final} cap={this.state.cap} tiers={this.state.tiers}></TierTable>
              </section>
              <section className="tier-sliders">
                <SimpleSelect setVal={this.setTierOrSplitValue.bind(this,'tier')} value={[...tierRange]} step={1} prefix={'$'} max={500000} label={'Tier Ranges'}></SimpleSelect>
                <SimpleSelect setVal={this.setTierOrSplitValue.bind(this,'split')} value={[...splitRange]} step={1} suffix={'%'} max={100} label={'Splits'}></SimpleSelect>
              </section>
              <section className="tier-sliders">
                <label className="segment-label">Change Rationale </label>
                <input onChange={evt => this.setRationale(evt.target.value, 'rationale')} value={this.state.rationale} type="text" className="validate set-name" />
              </section>
              <section className="btn-container">
                <Button 
                disabled={this.testSimilarity(this.state.orginalName, this.state.title)} 
                waves='light' onClick={() => this.props.confirm(this.state)} className='edit-button-text button-text' >Confirm Edits</Button>
              </section>
            </section>
          </div>
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
      return {
          plans: state.plans,
          current: state.current
      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          planAction: bindActionCreators(PlanActions, dispatch),
          currentAction: bindActionCreators(currentPlanActions, dispatch)
      }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditPlan)