import React, { Component } from 'react';
import './PlanSwitch.css';

class PlanSwitch extends Component {
    render() {
      return (
        <div className="switch structure-switch">
        <label className="switch-label">
        <span style={{color: this.props.label === 'Freedom Structure' ? 'black' : '#828EA1' }}>Freedom Structure</span>
          <input onChange={() => this.props.change(this.props.feeType)} type="checkbox"/>
          <span className="lever"></span>
          <span style={{color: this.props.label === 'Accelerator Structure' ? 'black' : '#828EA1' }}>Accelerator Structure</span>
          {/* {this.props.label} */}
        </label>
      </div>
      );
    }
  }
  
  export {PlanSwitch}